<template>
  <div>
    <v-card-text class="text-Heading6">首頁</v-card-text>

    <v-row>
      <v-col cols="12" sm="8" order="2" order-sm="1">
        <template
          v-if="!Merchant.is_prepaid_fee_enough && Merchant.used_fee_updated_at"
        >
          <v-card>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="6" sm="auto">
                  <div class="text-DisplayLarge Bold">手續費額度</div>
                </v-col>
              </v-row>
              <v-alert
                border="left"
                colored-border
                color="Error500"
                class="Error200"
              >
                <p class="Error500--text">預付額度已用完</p>
                <p class="Secondary500--text mb-0 text-body-2">
                  {{
                    $moment(Merchant.used_fee_updated_at).format(
                      'YYYY-MM-DD H:mm:ss'
                    )
                  }}
                </p>
              </v-alert>
            </v-card-text>
          </v-card>
          <SizeBox height="12" />
        </template>

        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="6" sm="auto">
                <div class="text-DisplayLarge Bold">商戶績效</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      平台總成交額
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.total_amount | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      總成交訂單數
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.total_order_count }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      實際收入總額(包含手動加款)
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.total_credit_amt | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      累計提現總額
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.total_commission_amt | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      今日成交金額
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.today_order_amount | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      今日成交訂單
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.today_order_count | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      今日實際收入(包含手動加款)
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.today_credit_amt | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      今日提現金額
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.today_commission_amt | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="6">
                <v-card class="title_with_line Surface100" flat>
                  <div class="titles">
                    <div
                      class="title_with_line-title OnSurface500--text text-Body"
                    >
                      商戶資金餘額
                    </div>
                    <div
                      class="
                        title_with_line-subtitle
                        OnSurface900--text
                        text-DisplayRegular
                        Medium
                      "
                    >
                      {{ result.merchant_balance | currency }}
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" order="1" order-sm="2">
        <div class="px-2 pr-sm-4">
          <v-card elevation="0" class="mb-6 b-rad-20">
            <v-img
              :src="require(`@/assets/images/mrCardBg.svg`)"
              :aspect-ratio="373 / 164"
            >
              <div class="fill-all flex-center flex-column">
                <div>{{ Merchant.name }}, 歡迎回來！</div>
              </div>
            </v-img>
          </v-card>

          <v-card>
            <v-card-title class="text-DisplayLarge Bold flex-center">
              商戶績效
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between align-center my-3">
                <span>上級商戶</span>
                <span>{{ Merchant.agent ? Merchant.agent.name : '無' }}</span>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between align-center my-3">
                <span>商戶編號</span>
                <span>{{ Merchant.serial }}</span>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between align-center my-3">
                <span>商戶名稱</span>
                <span>{{ Merchant.name }}</span>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between align-center my-3">
                <span>代付API</span>
                <v-chip
                  dark
                  v-if="Merchant.enabled_pay_api"
                  small
                  color="primary"
                >
                  開啟
                </v-chip>
                <v-chip dark v-else small color="error">關閉</v-chip>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between align-center my-3">
                <span>代付API自動審核</span>
                <v-chip dark small color="primary">開啟</v-chip>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <SizeBox height="16" />
  </div>
</template>

<script>
import { getIndexSummary } from '@/api/merchantCenter/reports';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      result: {
        total_amount: 0,
        total_order_count: 0,
        total_credit_amt: 0,
        total_commission_amt: 0,
        merchant_balance: 0,
        today_order_amount: 0,
        today_order_count: 0,
        today_credit_amt: 0,
        today_commission_amt: 0
      }
    };
  },

  computed: {
    ...mapGetters({
      Merchant: 'auth/getMerchant'
    })
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const Res = await getIndexSummary();

      if (!Res.error) {
        this.$set(this, 'result', Res);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title_with_line {
  position: relative;
  display: flex;
  align-items: stretch;

  padding-left: 24px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;

  .titles {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-top: 10px;
    margin-bottom: 10px;

    .title_with_line-title {
      margin-bottom: 6px;
    }

    .title_with_line-subtitle {
      padding-left: 2px;
    }
  }

  .title_line {
    position: relative;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 2px;
    margin-right: 4px;
  }
}
</style>
